import { createReducer, on } from '@ngrx/store';
import * as DataActions from './flight-order.action';
import { FlightOrder } from 'src/app/models/FlightOrder';

export interface FlightOrderState {
  orders: FlightOrder[];
}

export const initialState: FlightOrderState = {
  orders: [],
};

export const flightOrderReducer = createReducer(
  initialState,
  on(DataActions.setFlightOrders, (state, { orders }) => ({
    ...initialState,
    orders,
  }))
);
