import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-language-modal',
  templateUrl: './language-modal.page.html',
  styleUrls: ['./language-modal.page.scss'],
})
export class LanguageModalPage implements OnInit {

  constructor(private modalController: ModalController, private translate: TranslateService) { }

  ngOnInit() {
  }

  changeLanguage(lang: string) {
    this.translate.use(lang);
    this.translate.setDefaultLang(lang);
    this.dismiss();
  }

  dismiss() {
    this.modalController.dismiss();
  }
}


