import { Injectable } from '@angular/core';
import { v4 as uuidv4 } from 'uuid';
import { Preferences } from '@capacitor/preferences';

@Injectable({
  providedIn: 'root',
})
export class SessionService {
  private readonly STORAGE_KEY = 'sessionUUID';

  constructor() { }

  generateSessionUUID(): string {
    // Check if the session UUID is already stored
    const storedUUID = localStorage.getItem(this.STORAGE_KEY);

    if (storedUUID) {
      // If it exists, return the stored UUID
      return storedUUID;
    } else {
      // If it doesn't exist, generate a new UUID, store it, and return it
      const newUUID = uuidv4();
      localStorage.setItem(this.STORAGE_KEY, newUUID);
      return newUUID;
    }
  }

  refreshSessionUUID(): string {
    const newUUID = uuidv4();
    localStorage.setItem(this.STORAGE_KEY, newUUID);
    return newUUID;
  }

  async setValue(key: string, value: any) {
    await Preferences.set({
      key, value
    });
  }

  // JSON "get" example
  async getValue(key: string): Promise<string | null> {
    const ret = await Preferences.get({ key });
    return ret.value
  }

  async deleteValue(key: string) {
    await Preferences.remove({ key })
  }
}
