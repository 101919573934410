import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpParams,
} from '@angular/common/http';
import { environment } from 'src/environments/environment';
import {
  Observable,
  catchError,
  flatMap,
  from,
  mergeMap,
  of,
  switchMap,
  throwError,
} from 'rxjs';
import { AuthService } from './auth.service';
import { HttpHeaders } from '@angular/common/http';
import { ToastController, ToastOptions } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { SessionService } from './session.service';
import { ApiClientOptions } from '../models/ApiClient';

@Injectable({
  providedIn: 'root',
})
export default class ApiClientService {
  private apiBaseUrl: string = environment.apiServer;

  private header = {
  };

  private sessionUuid: string = this.sessionServ.generateSessionUUID();

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private toastCtrl: ToastController,
    private translateServ: TranslateService,
    private sessionServ: SessionService
  ) { }

  get(path: string, query?: any, options?: ApiClientOptions): Observable<any> {
    const apiUrl = this.apiBaseUrl + path;
    const params = new HttpParams({ fromObject: query });
    const token$ = from(this.authService.getToken());
    return token$.pipe(
      // Switch to a new observable that depends on the token
      switchMap((token) => {
        const headers = this.getHeaders(token);
        return this.http
          .get(apiUrl, { params, headers })
          .pipe(catchError((error) => this.error(error, options)));
      })
    );
  }

  post(path: string, body: object, options?: ApiClientOptions): Observable<any> {
    const apiUrl = this.apiBaseUrl + path;
    const token$ = from(this.authService.getToken());
    return token$.pipe(
      switchMap((token) => {
        const headers = this.getHeaders(token);
        return this.http
          .post(apiUrl, body, { headers })
          .pipe(catchError((error) => this.error(error, options)));
      })
    );
  }

  getHeaders(token?: string): HttpHeaders {
    let headers = new HttpHeaders({
      'SESSION-UUID': this.sessionUuid,
      ...this.header,
    });

    if (token) headers = headers.append('Authorization', `Token ${token}`);

    return headers;
  }

  refreshSession() {
    this.sessionUuid = this.sessionServ.refreshSessionUUID();
  }

  // Handle Errors
  error(err: HttpErrorResponse, options?: ApiClientOptions): Observable<never> {
    let errorMessage: string | null = null;
    const duration = !options?.manualClose ? 2500 : undefined;
    if (!options?.skipToast) {
      if (err.error instanceof ErrorEvent) {
        errorMessage = err.error.message;
      } else {
        errorMessage = `Error Code: ${err.status}\nMessage: ${err.message}`;
      }
      let toastProps: ToastOptions | undefined;

      const errorStatus = err.status

      if (errorStatus === 0) {
        toastProps = {
          message: this.translateServ.instant('API_RESPONSE.SERVER_DOWN'),
          color: 'danger',
          duration: duration,
          position: 'bottom',
        }
      }
      else if (errorStatus === 403) {
        toastProps = {
          message: this.translateServ.instant('API_RESPONSE.NON_AUTHORIZATION'),
          color: 'danger',
          duration: duration,
          position: 'bottom',
        }
      } else if (errorStatus === 500) {
        toastProps = {
          message: this.translateServ.instant('API_RESPONSE.INTERNAL_ERROR'),
          color: 'danger',
          duration: duration,
          position: 'bottom',
        }
      } else if (errorStatus === 401) {
        toastProps = {
          message: this.translateServ.instant('API_ERROR.UNLOGGED'),
          color: 'danger',
          duration: duration,
          position: 'bottom',
        }
        this.authService.logout();
      }
      else if (errorStatus === 404) {
        toastProps = {
          message: this.translateServ.instant('API_RESPONSE.NOT_FOUND'),
          color: 'danger',
          duration: duration,
          position: 'bottom',
        }
      } else if (errorStatus === 400) {
        const errorDetails: string[] = []
        const apiErrorPrefix: string = 'API_ERROR.'
        if (typeof err.error === 'object') {
          err.error.forEach((err: any) => {
            const errorTitle: string = err.title || ''
            const errorDetail: string = err.detail || errorTitle
            let detailTranslate: string = this.translateServ.instant(apiErrorPrefix + errorDetail)

            if (detailTranslate === apiErrorPrefix + errorDetail) {
              detailTranslate = this.translateServ.instant(apiErrorPrefix + errorTitle)
              if (detailTranslate === apiErrorPrefix + errorTitle) {
                detailTranslate = errorTitle + ":" + errorDetail
              }
            }

            errorDetails.push(detailTranslate.replace(apiErrorPrefix, ""));
          })
        } else if (typeof err.error === 'string') {
          // const errorMsg: string = this.translateServ.instant(apiErrorPrefix + err.error)
          errorDetails.push(err.error)
        }
        toastProps = {
          message: errorDetails.join(", "),
          color: 'primary',
          duration: duration,
          position: 'bottom',
        }
      }

      if (!!toastProps) {
        this.toastCtrl.create({
          ...toastProps, buttons: [
            {
              icon: 'close',
              htmlAttributes: {
                'aria-label': 'close',
              },
            },
          ],
        }).then(t => t.present());
      }
    }

    return throwError(() => {
      return errorMessage || err;
    });
  }
}
