// data.effects.ts

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import * as DataActions from './location.action';
import { HotDealsService } from 'src/app/services/api/hot-deals.service';
import { LocationService } from 'src/app/services/api/location.service';
import { Position } from '@capacitor/geolocation';

@Injectable()
export class LocationEffects {
  constructor(
    private actions$: Actions,
    private dataService: LocationService
  ) {}

  loadData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DataActions.loadClosestAirport),
      switchMap((position) =>
        this.dataService
          .getNearestAirport(position.position)
          .pipe(map((location) => DataActions.setClosestAirport({ location })))
      )
    )
  );
}
