import { createReducer, on } from "@ngrx/store";
import * as DataActions from './settings.action'

export interface SettingsState {
    minReserveFlightDepartureHours: number;
}

export const initialState: SettingsState = {
    minReserveFlightDepartureHours: 36,
}

export const settingsReducer = createReducer(
    initialState,
    on(DataActions.loadSettingsAction, (state) => ({ ...state })),
    on(DataActions.settingLoadSuccess, (state, settings) => ({ ...state, settings }))
);