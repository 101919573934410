import { createAction, props } from '@ngrx/store';
import { Bags, BookingRequirements, FlightOffer, Traveler } from 'src/app/models/autogen-models/models';
import { FlightPriceIncludeBags } from 'src/app/models/FlightPriceResponse';
import { BookingFormContact } from 'src/app/models/FlightOfferBooking';
import { ItinerariesAdditionalBags, TravelerItinerarySelectedAdditionalBags } from 'src/app/models/FlightAdditionalBags';

export const setFlightBooking = createAction(
  '[Data] Set Data flight booking',
  props<{ offer: FlightOffer, bags: FlightPriceIncludeBags, bookingRequirements: BookingRequirements }>()
);

export const startFlightBooking = createAction(
  '[Data] Set flight travelers and contact',
  props<{ travelers: Traveler[], contact: BookingFormContact }>()
);

export const updateFlightBookingOffer = createAction(
  '[Data] Update flight offer',
  props<{ offer: FlightOffer }>()
);

export const updateFlightAdditionalBags = createAction(
  '[Data] Update flight additional bags',
  props<{ additionalBags: ItinerariesAdditionalBags[] }>()
);

export const resetFlightBooking = createAction(
  '[Data] Reset flight booking'
);
