import { createReducer, on } from '@ngrx/store';
import { loadClosestAirport, setClosestAirport } from './location.action';
import { Airport } from 'src/app/models/FlightSearchResponse';

export interface LocationState {
  nearestLocation: Airport|null;
}

export const initialState: LocationState = {nearestLocation:null};

export const locationReducer = createReducer(
  initialState,
  on(loadClosestAirport, (state, {position}) => ({...state, position})),
  on(setClosestAirport, (state, { location }) => ({
    ...state,
    nearestLocation: location,
  }))
);
