import { Injectable } from '@angular/core';
import { persistState, Store, StoreConfig } from '@datorama/akita';
import { FlightOffer } from 'src/app/models/autogen-models/models';

export interface OfferState {
    offer?: FlightOffer,
    expireAt?: Date,
    storedOfferId?: string
}


export function createInitialState(): OfferState {
    return {
    };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'offer' })
export class OfferStore extends Store<OfferState> {
    constructor() {
        super(createInitialState());
    }
}

export const offerPersistStorage = persistState({
    include: ['offer'],
    key: 'offerStore',
    deserialize: (value: any) =>
        JSON.parse(value, (key, val) => {
            return key === 'expireAt' ? val ? new Date(val) : val : val;
        }),
});