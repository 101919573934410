import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { firstValueFrom } from 'rxjs';
import { Duration } from 'src/app/models/duration';
import { getDurationFromISO } from 'src/app/utils/date-tools';

@Pipe({
  name: 'duration',
})
export class DurationPipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  // transform(duration: string): string {
  //   const durationParts = duration.split('T');
  //   const hours = durationParts[1].slice(0, -1);
  //   const minutes = Math.round(parseInt(hours.slice(-2), 10) / 60 * 100);
  //   return `${durationParts[0].slice(1)}d ${hours.slice(0, -2)}:${minutes.toString().padStart(2, '0')}h`;
  // }

  async transform(duration: string | undefined | Duration): Promise<string> {
    const durationObj: Duration =
      typeof duration === 'object' ? duration : getDurationFromISO(duration);

    const hourChar = await firstValueFrom(this.translateService.get('HOUR'));
    const minuteChar = await firstValueFrom(
      this.translateService.get('MINUTE')
    );
    const secondChar = await firstValueFrom(
      this.translateService.get('SECOND')
    );

    let hours = durationObj.hours;
    let minutes = durationObj.minutes;
    let seconds = durationObj.seconds;
    if (hours > 0) {
      if (minutes > 0) {
        return `${hours}${hourChar}${minutes}${minuteChar}`;
      } else {
        return `${hours}${hourChar}`;
      }
    } else if (minutes > 0) {
      if (seconds > 0) {
        return `${minutes}${minuteChar} ${seconds}${secondChar}`;
      } else {
        return `${minutes}${minuteChar}`;
      }
    } else if (seconds > 0) {
      return `${seconds}${secondChar}`;
    } else {
      // return `0${secondChar}`;
    }
    return '';
  }
}
