<ion-header>
  <ion-toolbar>
    <ion-title>
      {{'LANGUAGE'| translate}}
    </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-list>
    <ion-item (click)="changeLanguage('zh')">中文</ion-item>
    <ion-item (click)="changeLanguage('es')">Español</ion-item>
  </ion-list>
</ion-content>
