import { Duration } from '../models/duration';

export function dateToDateString(date: Date): string {
  return date.toISOString().split('T')[0];
}

export function dateToTimeString(date: Date): string {
  return date.toISOString().split('T')[1].split('.')[0];
}

export function getDurationFromISO(duration: string | undefined): Duration {
  const value: Duration = { hours: 0, minutes: 0, seconds: 0 };

  if (!duration) {
    return value;
  }

  let hours = 0;
  let minutes = 0;
  let seconds = 0;

  if (duration.startsWith('P')) {
    duration = duration.substring(1);

    if (duration.includes('T')) {
      const [date, time] = duration.split('T');

      if (date.includes('D')) {
        const days = parseInt(date.split('D')[0], 10);
        hours += days * 24;
      }

      if (time.includes('H')) {
        hours += parseInt(time.split('H')[0], 10);
      }

      if (time.includes('M')) {
        const minuteSplit: string | undefined = time
          .split('M')[0]
          .split('H')
          .pop();
        if (undefined !== minuteSplit) {
          minutes += parseInt(minuteSplit, 10);
        }
      }

      if (time.includes('S')) {
        seconds += parseInt(time.split('S')[0], 10);
      }
    } else {
      if (duration.includes('D')) {
        const days = parseInt(duration.split('D')[0], 10);
        hours += days * 24;
      }
    }
  }

  value.hours = hours;
  value.minutes = minutes;
  value.seconds = seconds;

  return value;
}
