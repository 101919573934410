import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { DurationPipe } from './duration.pipe';


@NgModule({
  declarations: [DurationPipe],
  imports: [CommonModule, IonicModule],
  exports: [DurationPipe]
})
export class DurationPipeModule {}
