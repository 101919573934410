<ion-app>
  <ion-router-outlet></ion-router-outlet>

  <ion-modal [isOpen]="cookieModal" [canDismiss]="!cookieModal" class="fit-content-modal">
    <ng-template>
      <ion-item lines="none">
        <ion-text>
          {{ "COOKIE.MESSAGE" | translate }}
        </ion-text>
      </ion-item>
      <ion-item [routerLink]="['/privacy-policy']" class="underline">
        {{"PRIVACY_POLICY" | translate}}
      </ion-item>
      <ion-button (click)="triggerCookie(true)">
        {{ "COOKIE.ACCEPT" | translate }}
      </ion-button>
      <ion-button (click)="triggerCookie(false)" color="light">
        {{ "COOKIE.LEARN_MORE" | translate }}
      </ion-button>
    </ng-template>
  </ion-modal>
</ion-app>