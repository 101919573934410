import { Component, Inject, LOCALE_ID, NgZone, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { LanguageModalPage } from './pages/language-modal/language-modal.page';
import { Storage } from '@ionic/storage-angular';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { ActivatedRoute, NavigationEnd, NavigationStart, Route, Router } from '@angular/router';

import { register } from 'swiper/element/bundle';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Title } from '@angular/platform-browser';
import { Analytics, logEvent } from '@angular/fire/analytics';
import { take } from 'rxjs';

register();

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  public appPages = [
    { title: 'MENU.EXPLORE', url: '/home', icon: 'search-outline' },
    { title: 'MENU.TRIPS', url: '/trips', icon: 'bag-handle-outline' },
    { title: 'MENU.PROFILE', url: '/profile', icon: 'person-circle-outline' },
  ];
  currentUrl = '/home';

  param = { value: 'world' };
  availableLanguages = [
    'es', 'zh'
  ];

  appName: string = '';

  firebaseChecked: boolean = false;
  message: any = null;
  cookieModal: boolean = false;

  constructor(
    private modalController: ModalController,
    private translateService: TranslateService,
    private storage: Storage,
    private router: Router,
    private zone: NgZone,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private firebaseAuth: AngularFireAuth,
    private analytics: Analytics,
    // private afMessaging: AngularFireMessaging,
  ) {
    router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.currentUrl = val.url;
      }
    });

    firebaseAuth.onAuthStateChanged((s) => {
      this.firebaseChecked = true;
    });

    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        logEvent(analytics, 'screen_view');
      }
    });

    this.translateService.onLangChange.subscribe(() => {
      this.translateService.get('APP_NAME').pipe(take(1)).subscribe((title) => {
        this.appName = title;
      });
    });

    if (localStorage.getItem('cookie') !== 'true' && window.location.pathname !== '/privacy-policy') {
      this.cookieModal = true;
    }
  }

  async ngOnInit() {
    await this.storage.create();

    this.checkLanguage();

    // this.initializeApp();
    this.routerTitle();
  }

  async openLanguageModal() {
    const modal = await this.modalController.create({
      component: LanguageModalPage,
    });
    return await modal.present();
  }

  checkLanguage() {
    this.translateService.addLangs(this.availableLanguages);
    this.translateService.setDefaultLang('zh');
    const browserLang: string = this.translateService.getBrowserLang() || '';
    const setLang: string = this.availableLanguages.includes(browserLang) ? browserLang : 'zh';
    this.translateService.use(setLang);
    this.translateService.get('APP_NAME').pipe(take(1)).subscribe((title) => {
      this.appName = title;
    });
  }

  initializeApp() {
    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      this.zone.run(() => {
        // Example url: https://beerswift.app/tabs/tab2
        // slug = /tabs/tab2
        const slug = event.url.split('.es').pop();
        if (slug) {
          this.router.navigateByUrl(slug);
        }
        // If no match, do nothing - let regular routing
        // logic take over
      });
    });

    // Firebase Cloud Messaging
    // this.afMessaging.requestPermission.subscribe(
    //   () => {
    //     console.log('Permission granted!');
    //     // Handle successful permission grant
    //     this.afMessaging.getToken.subscribe((token) => {
    //       console.log(token);
    //     });
    //   },
    //   (error) => {
    //     console.log('Permission denied:', error);
    //     // Handle permission denied or error
    //   }
    // );

    // this.afMessaging.onMessage((message) => {
    //   console.log('Message received onMessage:', message);
    //   // Handle the received message here
    // });
    // this.afMessaging.messages.subscribe((message) => {
    //   console.log('Message received messages:', message);
    //   // Handle the received message here
    // });
  }

  routerTitle() {
    this.router.events.subscribe((evs) => {
      if (evs instanceof NavigationStart) {
        if (localStorage.getItem('cookie') !== 'true' && evs.url !== '/privacy-policy') {
          this.cookieModal = true;
        } else {
          this.cookieModal = false;
        }
      }

      if (evs instanceof NavigationEnd) {

        let route = this.activatedRoute.firstChild;
        while (route?.firstChild) route = route.firstChild;
        if (route?.outlet === 'primary') {
          route.data.subscribe((data) => {
            data &&
              data['title'] &&
              this.translateService.stream(data['title']).subscribe((title) => {
                this.titleService.setTitle(`${title} - ${this.appName}`);
              });
          });
        }
      }
    });
  }

  triggerCookie(accept: boolean) {
    if (!accept) {
      this.router.navigate(['/privacy-policy']);
    }
    localStorage.setItem('cookie', `${accept}`);
    this.cookieModal = false;
  }
}
